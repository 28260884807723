import './src/styles/styles.less';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import type { GatsbyBrowser } from 'gatsby';

// Accessibility
export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
	const s = document.createElement('script'),
		e = !document.body ? document.querySelector('head') : document.body;
	s.src = 'https://acsbapp.com/apps/app/assets/js/acsb.js';
	s.async = s.defer = true;
	s.onload = function () {
		window.acsbJS.init({
			statementLink: '',
			feedbackLink: '',
			footerHtml: '',
			hideMobile: false,
			hideTrigger: false,
			language: 'en',
			position: 'right',
			leadColor: '#146FF8',
			triggerColor: '#146FF8',
			triggerRadius: '50%',
			triggerPositionX: 'right',
			triggerPositionY: 'bottom',
			triggerIcon: 'default',
			triggerSize: 'medium',
			triggerOffsetX: 20,
			triggerOffsetY: 20,
			mobile: {
				triggerSize: 'small',
				triggerPositionX: 'right',
				triggerPositionY: 'center',
				triggerOffsetX: 0,
				triggerOffsetY: 0,
				triggerRadius: '50%'
			}
		});
	};
	e.appendChild(s);
};
